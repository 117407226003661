import React from 'react';
import {formatBytes} from "../../js/fileUtils";

const FileCard = ({info}) => {
	return (
		<div className='fileCard get-direction fade-in-bottom' key={info.name}>
			<div className='fileLead'>
				<div className='fileCardDet'>
					<svg className='get-svg' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
						<path fill="currentColor" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6z"/>
					</svg>
					<h1 className='fileName'>{(info.name !== undefined) ? info.name.slice(0, 24) + '...' : 'reactgovno'}</h1><h4
					className='fileSize'>{formatBytes(info.size, 2)}</h4>
				</div>
			</div>
			<div className='fileProgress' id = {info.id + '_BP'} style = {{"width": 0}}></div>
		</div>
	);
};

export default FileCard;
