import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {joinRoom} from "trystero";
import {$$, destroyElem, showToast} from "../../js/ui";
import {downloadBlob, formatBytes, uuidv4} from "../../js/fileUtils";
import FileCard from "../../components/filecard";
import ReactDOM from 'react-dom';
import Nav from "../../components/nav";
import NavBar from "../../components/nav";

let filesList = [];

const ChannelPage = ({tabName}) => {
	let identifyCode = useParams()['id'];
	
	let currentURL = window.location.href.replace(tabName, '');
	
	let devCounter = 1;
	
	let isConnected = false;
	const idsToNames = {}

	let [files, setFile, updateFiles] = useState([]);
	let [toasts, setToast] = useState('');
	
	const config = {appId: 'DISM_CDA'};
	let room = joinRoom(config, identifyCode);
	let [sendFile, getFile, onFileProgress] = getConnection();
	const [sendName, getName] = room.makeAction('name')
	
	if (identifyCode !== null){
		createConnection(identifyCode);
	}
	
	function selectFile(){
		let f = $$.createElement('input');
		f.type = 'file';
		f.click();
		
		f.onchange = () => {
			let file = f.files[0];
			
			sendFiles(file);
		}
	}
	
	function sendFiles(file){
		sendFile(file, null, {name: file.name, type: file.type, size: file.size, id: uuidv4()}, (percent, peerId) => {
			//setFile(createCard(file.name))
			//$$.getElementById('TRX').appendChild(createCard(file.name));
		})
	}
	
	function getConnection(){
		return room.makeAction('file');
	}
	
	function createConnection(code) {
		sendName('DEVICE ' + MediaDeviceInfo.deviceID)
		
		getFile((data, peerId, metadata) => {
			console.log(
				`got a file (${metadata.name}) from ${peerId} with type ${metadata.type} and size ${metadata.size} bytes`,
				data
			)
			
			downloadBlob(data, metadata.name, metadata.type);
		})
		
		onFileProgress((percent, peerId, metadata) => {
			addFile(metadata, percent)
			
			//$$.getElementById(metadata.size + '_BP').style.width = String(percent*100 + '%');
			
		})
		
		room.onPeerJoin(peerId => {
			sendName('DEVICE ' + MediaDeviceInfo.deviceID, peerId)
			
			setNewToast({"text": "Новое устройство подключено к каналу"});
			
			getName((name, peerId) => {idsToNames[peerId] = name;
				console.log(idsToNames)})
			
			$$.getElementById('net-state').classList.forEach((e) => {
				$$.getElementById('net-state').classList.remove(e);
			});
			
			$$.getElementById('net-state').classList.add('net-state-ok');
			$$.getElementById('net-state').textContent = 'В сети';
			
			devCounter++;
			updateDeviceCounter()
			
			isConnected = !isConnected
		})
		
		room.onPeerLeave(peerId => {
			$$.getElementById('net-state').classList.forEach((e) => {
				$$.getElementById('net-state').classList.remove(e);
			});
			
			$$.getElementById('net-state').classList.add('net-state-err');
			$$.getElementById('net-state').textContent = 'Не в сети';
			
			devCounter--;
			updateDeviceCounter()
		})
	}
	
	function updateDeviceCounter(){
		$$.getElementById('DEVICE_COUNTER').textContent = devCounter;
	}
	
	function setNewToast(content){
		setToast(showToast(content))
		
		setTimeout(() => { setToast('') }, 5000)
	}
	
	function addFile(metadata, perc){
		let result = files.find(({ id }) => String(id) === String(metadata.id));
		let resultIndex = files.findIndex(({ id }) => String(id) === String(metadata.id));
		
		if (result === undefined){
			setFile(
				[
					...files,
					{"name": metadata.name, "percent": perc, "size": metadata.size, "id": metadata.id}
				]
			);
		} else {
			files[resultIndex].percent = Math.round(Number(perc*100))
			
			$$.getElementById(files[resultIndex].id + '_BP').style.width = Math.round(perc*100)+'%';
		}
		
		filesList = files;
	}
	
	/*
	* TABS
	*/
	
	function tabMain(){
		return <>
			<div className='trans-info fade-in-right'>
				<div className='info'>
					<svg className='red-sv' xmlns="http://www.w3.org/2000/svg" width="24" height="24"
					     viewBox="0 0 24 24">
						<path fill="currentColor" d="M13 14h-2V9h2m0 9h-2v-2h2M1 21h22L12 2z"/>
					</svg>
					<p className='info-ds'><b>Передача данных по протоколу BitTorrent</b><br/>Для функционирования
						пиринговой сети требуется как минимум 2 участника (пира)</p>
				</div>
				<h1 className='base-m'>Канал <b>{identifyCode}</b></h1>
				<h5 className='cn-state'>Соединение <b id='net-state' className='net-state-wait'>Ожидание...</b>
				</h5>
				<div className='dist-cs'>
					<button className='btn btn-ac' onClick={() => {
						selectFile()
					}}>Отправить файл
					</button>
					<button className='btn btn-sc'>Указать ссылку</button>
				</div>
			</div>
			<div className='transfers' id='TRX'>
				<div className='transport-info'>
					{files.map((el) => <FileCard info={el}/>)}
				</div>
			</div>
		</>
	}
	
	function tabTransfers(){
		return <>
			<h1 className='base-m fade-in-right'>Передачи</h1>
			<div className='transfers'>
				<div className='transport-info'>
					{files.map((el) => <FileCard info={el}/>)}
				</div>
			</div>
		</>
	}
	
	let tabContent;
	
	switch (tabName){
		case 'main':
			tabContent = tabMain()
		break;
		
		case 'transfers':
			tabContent = tabTransfers()
		break;
		
		default:
		break;
	}
	
	return <>
		<NavBar/>
		<div className='tab-list'>
			<Link to={currentURL + 'main'}>
				<div className={'tab' + ((tabName === 'main') ? ' tab-sl' : '')}>
					<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
						<path fill="currentColor" d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8z"/>
					</svg>
					Главная
				</div>
			</Link>
			<Link to={currentURL + 'transfers'}>
				<div className={'tab' + ((tabName === 'transfers') ? ' tab-sl' : '')}>
					<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
						<path fill="currentColor"
						      d="M2 2h9v9H2zm7 2H4v5h5zm13 9v9h-9v-9zm-7 7h5v-5h-5zm1-12v3h-3V8zm-5 8H8v-3h3z"/>
					</svg>
					Передачи
					<h5 className='dev-counter'>0</h5>
				</div>
			</Link>
			<div className='tab'>
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
					<path fill="currentColor"
					      d="M23 11h-5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1m0 9h-5v-7h5zM20 2H2C.89 2 0 2.89 0 4v12a2 2 0 0 0 2 2h7v2H7v2h8v-2h-2v-2h2v-2H2V4h18v5h2V4a2 2 0 0 0-2-2m-8.03 7L11 6l-.97 3H7l2.47 1.76l-.94 2.91l2.47-1.8l2.47 1.8l-.94-2.91L15 9z"/>
				</svg>
				Устройства
				<h5 className='dev-counter' id='DEVICE_COUNTER'>1</h5>
			</div>
			<div className='tab'>
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
					<path fill="currentColor"
					      d="M17.25 12c0 .23-.02.46-.05.68l1.48 1.16c.13.11.17.29.08.45l-1.4 2.42c-.09.15-.27.21-.43.15l-1.74-.7c-.36.28-.76.51-1.19.69l-.25 1.85c-.03.17-.18.3-.35.3h-2.8c-.17 0-.32-.13-.35-.3L10 16.85c-.44-.18-.83-.41-1.19-.69l-1.74.7c-.16.06-.34 0-.43-.15l-1.4-2.42a.35.35 0 0 1 .08-.45l1.48-1.16c-.03-.22-.05-.45-.05-.68s.02-.46.05-.68l-1.48-1.16a.35.35 0 0 1-.08-.45l1.4-2.42c.09-.16.27-.22.43-.16l1.74.71c.36-.28.75-.52 1.19-.69l.25-1.86c.03-.16.18-.29.35-.29h2.8c.17 0 .32.13.35.29L14 7.15c.43.17.83.41 1.19.69l1.74-.71c.16-.06.34 0 .43.16l1.4 2.42c.09.15.05.34-.08.45l-1.48 1.16c.03.22.05.45.05.68M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-7 7c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"/>
				</svg>
				Настройки
			</div>
		</div>
		<div className='transfer-app'>
			{tabContent}
			{console.log('is conn', isConnected)}
			{console.log('files list', files)}
			{toasts}
		</div>
	</>
};

export function getFileProgress(id){
	let result = filesList.find(({ id }) => String(id) === String(id));
	
	return result;
}


export default ChannelPage;
