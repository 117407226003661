import React from 'react';
import {$$} from "../../js/ui";
import QRCode from "qrcode";
import {joinRoom} from 'trystero'
import {uuidv4} from "../../js/fileUtils";

let id = uuidv4();
const HomePage = () => {
	let channelURL = window.location.href + 'channel/' + id;
	
	return (
		<>
			<div className='semi-wrap'>
				<div className='com-div' id = 'STEP0'>
					<h1 className='base-h'>Знакомьтесь, <b>TRANSPONDER</b></h1>
					<p className='desc-p'>Передавайте файлы между разными типами устройств<br/>
						бесплатно и без ограничений,<br/>
						больше никаких проводов
					</p>
					<button className='btn btn-ac' onClick={() => {__genQR(channelURL)}}>Начать</button>
				</div>
				<div className='smi' id='STEP1' style={{"display": "none"}}>
					<div className='conn-tut'>
						<h1 className='base-m'>Сканируйте QR-код для создания канала</h1>
						<div className='qr-wrap'>
							<span className="loader" id='LDX'></span>
							<canvas id="canvas" className='qr-channel'></canvas>
						</div>
						<p className='desc-p'>
							Или введите идентификатор канала для подключения
						</p>
						<div className='dist-cs'>
							<button className='btn btn-ac'>Сканировать QR</button>
							<button className='btn btn-sc'>Указать ссылку</button>
						</div>
						<p className='desc-px'>
							Идентификатор канала: <b>{id}</b>
						</p>
					</div>
					<div className='device-wait'>
						<p className='desc-p'>
						Ожидание другого устройства...
						</p>
						<span className="loaderx"></span>
					</div>
				</div>
			</div>
		</>
	);
};

function __genQR(url) {
	let canvas = $$.getElementById('canvas')
	
	QRCode.toCanvas(canvas, url, function (error) {
		if (error) console.error(error)
		console.log('success!');
		
		setup();
		
		$$.getElementById('LDX').style.display = 'none';
		$$.getElementById('STEP0').classList.add('fade-out-left');
		$$.getElementById('STEP0').addEventListener('animationend', () => {
			$$.getElementById('STEP0').style.display = 'none';
			$$.getElementById('STEP0').classList.remove('fade-out-left');
			
			$$.getElementById('STEP1').style.display = 'flex';
			$$.getElementById('STEP1').classList.add('fade-in-right');
			$$.getElementById('STEP1').addEventListener('animationend', () => {
				$$.getElementById('STEP1').classList.remove('fade-in-right');
			})
		})
		
	})
}

function setup(){
	let identifyCode = id;
	
	const config = {appId: 'DISM_CDA'}
	const room = joinRoom(config, identifyCode)
	
	room.onPeerJoin(peerId => {
		window.location.href = '/channel/' + identifyCode;
	})
}

export default HomePage;
