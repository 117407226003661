import Toast from "../components/toast";

export const APPNAME = 'TRANSPONDER';

	export const $$ = document;

	export function changeTabName(title){
		document.title = APPNAME + ' :: ' + title;
	}
	
	export function showToast(content){
		let toast = <Toast content={content}/>;
		
		return toast;
	}
	
	export function destroyElem(elem){
		elem.parentNode.removeChild(elem);
	}