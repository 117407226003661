import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home";
import ChannelPage from "./pages/channel";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route element={<HomePage/>} path = '/' index="true"></Route>
            <Route element={<ChannelPage tabName='main'/>} path = '/channel/:id/main'></Route>
            <Route element={<ChannelPage tabName='transfers'/>} path = '/channel/:id/transfers'></Route>
        </Routes>
    </BrowserRouter>
);

