import React from 'react';

const Toast = ({content}) => {
	return (
		<div className='toast fade-in-bottom'>
			<h3 className='toast-text'>{content.text}</h3>
		</div>
	);
};

export default Toast;
