
	export function downloadBlob(data, fileName, mimeType) {
		let blob, url;
		blob = new Blob([data], {
			type: mimeType
		});
		url = window.URL.createObjectURL(blob);
		downloadURL(url, fileName);
		setTimeout(function() {
			return window.URL.revokeObjectURL(url);
		}, 1000);
	}
	export function downloadURL(data, fileName) {
		let a;
		a = document.createElement('a');
		a.href = data;
		a.download = fileName;
		document.body.appendChild(a);
		a.style = 'display: none';
		a.click();
		a.remove();
	}
	
	export function formatBytes(bytes, decimals = 2) {
		if (!+bytes) return '0 Байт'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПТ', 'ЭБ', 'ЗБ', 'ЭБ']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}
	
	export function uuidv4() {
		return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
			(+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
		);
	}